import React from "react";

import Alert from "@kikoff/components/src/v1/info/Alert";
import KLink from "@kikoff/components/src/v1/navigation/KLink";
import { combineClasses } from "@kikoff/utils/src/string";

import { track } from "@util/analytics";
import { l10nResources } from "@util/l10n";

import styles from "./styles.module.scss";

const l10n = l10nResources({
  en: `mfa-banner-title = All logins now require verification by SMS or email
mfa-banner-message = For your security, you will verify by code after entering your email and password. Have trouble logging in?
mfa-banner-get-help = Get help
`,
  es: `mfa-banner-title = Todos los inicios de sesión ahora requieren verificación por SMS o correo electrónico
mfa-banner-message = Para su seguridad, verificará por código después de ingresar su correo electrónico y contraseña. ¿Tiene problemas para iniciar sesión?
mfa-banner-get-help = Consigue ayuda
`,
});

function MfaRequiredBanner() {
  return (
    <Alert type="info" className={styles.banner}>
      <div className="text:moderate color:strong">
        {l10n.getString("mfa-banner-title")}
      </div>
      <div
        className={combineClasses(styles.message, "text:small color:moderate")}
      >
        {l10n.getString("mfa-banner-message")}
      </div>
      <KLink
        target="_blank"
        href="https://kikoffsupport.zendesk.com/hc/en-us/articles/22697860176525-Kikoff-login-now-requires-two-factor-authentication"
        variant="underlined"
        onClick={() => {
          track("login banner: get help link clicked");
        }}
      >
        {l10n.getString("mfa-banner-get-help")}
      </KLink>
    </Alert>
  );
}

export default MfaRequiredBanner;
